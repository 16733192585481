<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:42:07
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="uploada animated bounceInRight">
    <header class="header">
      <div class="center_title">
        <div class="header_left">
          <img class="logo" src="../assets/logo.png" @click="home" alt>
          <img class="text" src="../assets/text2.png" @click="home" alt>
        </div>
        <div class="header_right">
          <img class="phone" src="../assets/phone.png" alt>
          <p class="phone_text">公司电话 1008611</p>
        </div>
      </div>
    </header>
    <div class="content">
      <div class="from">
        <p class="title_a">管理员</p>
        <div class="box">
          <el-form :label-position="labelPosition" label-width="1.7rem">
            <el-form-item label="企业名称">
              <el-input
                class="input"
                type="text"
                placeholder="请输入您所属公司营业执照上的公司名称"
                v-model="name"
                maxlength="46"
                show-word-limit
                @input="keyu"
              ></el-input>
            </el-form-item>
            <el-form-item label="统一社会信用代码">
              <el-input
                class="input"
                type="text"
                placeholder="请输入18位社会信用代码"
                v-model="phone_b"
                maxlength="18"
                show-word-limit
                @input="keyu"
              ></el-input>
            </el-form-item>
            <div class="iiinput">
              <el-form-item label="添加管理员手机号">
                <el-input
                  type="text"
                  placeholder="请输入11位手机号"
                  v-model="phone"
                  maxlength="11"
                  show-word-limit
                  @input="keyu"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="code_box">
            <input class="code_a" @keyup="keyu" type="text" placeholder="请输入验证码" v-model="codea">
            <div class="code" v-show="show" @click="code">获取验证码</div>
            <div class="code" v-show="!show">{{sj}}s</div>
          </div>
        </div>
        <div class="bottom_up">
          <div class="box_bottom">
            <div class="box_btm_left">
              <p>请上传营业执照</p>
              <div class="picture">
                <div class="image-view">
                  <div class="addbox" v-if="img_get">
                    <input type="file" @change="getImgBasea()">
                    <img class="img_1" src="../assets/increase.png" alt>
                  </div>
                  <div class="view">
                    <div class="item" v-for="(item, index) in imgBase64" :key="index">
                      <span class="cancel-btn" @click="delImga(index)">x</span>
                      <img v-image-preview :src="item" class="img_3">
                    </div>
                  </div>
                </div>
                <div class="example" v-if="img_get">
                  <img class="img_2" v-image-preview src="../assets/license.png" alt>
                  <p style="color:#adadad;">范例</p>
                </div>
              </div>
            </div>
            <div class="xian"></div>
            <div class="box_btm_right">
              <p>请上传管理员认证</p>
              <p style="font-size:0.14rem">(支持jpg、png、pdf、doc、docx)</p>
              <p style="color:#adadad;font-size:0.14rem;">文件大小不超过10M</p>
              <div class="picture">
                <div class="image-view">
                  <div class="addbox" v-if="img_getb">
                    <input type="file" @change="getImgBaseb()">
                    <img class="img_1" src="../assets/increase.png" alt>
                  </div>
                  <div class="view">
                    <div class="item" v-for="(item, index) in imgBase64a" :key="index">
                      <span class="cancel-btn" @click="delImgb(index)">x</span>
                      <img v-image-preview :src="item" class="img_3">
                    </div>
                  </div>
                </div>
                <div class="example" v-if="img_getb">
                  <img class="img_2" v-image-preview src="../assets/entrust.png" alt>
                  <p style="color:#adadad;">范例</p>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_no" v-if="btn_no">确定</div>
          <div class="btn" @click="btn_yes" v-if="btn">确定</div>
        </div>
      </div>
    </div>
    <footer class="footer">
      <foote></foote>
    </footer>
  </div>
</template>

<script>
import { upload_img, sms } from '../api/api.js'
import foote from '../components/Foote'
export default {
  name: 'uploada',
  data () {
    return {
      labelPosition: 'right',
      name: '',
      phone_b: '',
      phone: '',
      codea: '',
      btn: false,
      btn_no: true,
      imgBase64: [], // 存储img
      imgBase64a: [], // 存储img
      img_get: true,
      img_getb: true,
      show: true,
      sj: '',
      lei: '.',
      xin: '',
      lei_b: '.',
      xin_b: '',
      ent_id: ''
    }
  },
  components: {
    foote
  },
  mounted () {
    if (sessionStorage.getItem('ent_id') != null) {
      this.ent_id = JSON.parse(JSON.parse(sessionStorage.getItem('ent_id')))
    }
    console.log(this.ent_id.ent_id)
  },
  methods: {
    home () {
      this.$router.push('/')
    },
    // 获取图片base64实现预览
    getImgBasea () {
      var _this = this
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      _this.lei = file.name.substring(file.name.lastIndexOf('.'), file.name.length)

      // 转base64
      reader.onload = function (e) {
        _this.imgBase64.push(e.target.result)
        _this.xin = e.target.result.substring(
          e.target.result.indexOf(',') + 1,
          e.target.result.length
        )

        // _this.lei="."+e.target.result.substring(e.target.result.indexOf('/')+1,e.target.result.indexOf(';'))
      }
      reader.readAsDataURL(file)
      if (this.imgBase64.length == 0) {
        this.img_get = false
        if (
          this.name != 0 &&
          this.phone_b.length == 18 &&
          this.img_get == false &&
          this.img_getb == false
        ) {
          this.btn = true
          this.btn_no = false
        } else {
          this.btn = false
          this.btn_no = true
        }
      }
    },
    // 删除图片
    delImga (index) {
      this.imgBase64.splice(index, 1)
      this.img_get = true
      this.btn = false
      this.btn_no = true
    },
    // 获取图片base64实现预览   2222222222222222222
    getImgBaseb () {
      var _this = this
      var event = event || window.event
      var file = event.target.files[0]
      var reader = new FileReader()
      _this.lei_b = file.name.substring(
        file.name.lastIndexOf('.'),
        file.name.length
      )
      // 转base64
      reader.onload = function (e) {
        _this.imgBase64a.push(e.target.result)
        _this.xin_b = e.target.result.substring(
          e.target.result.indexOf(',') + 1,
          e.target.result.length
        )
      }
      reader.readAsDataURL(file)
      if (this.imgBase64a.length == 0) {
        this.img_getb = false
        if (
          this.name != 0 &&
          this.phone_b.length == 18 &&
          this.img_get == false &&
          this.img_getb == false &&
          /^1[3456789]\d{9}$/.test(this.phone) &&
          this.codea.length == 4
        ) {
          this.btn = true
          this.btn_no = false
        } else {
          this.btn = false
          this.btn_no = true
        }
      }
    },
    // 删除图片
    delImgb (index) {
      this.imgBase64a.splice(index, 1)
      this.img_getb = true
      this.btn = false
      this.btn_no = true
    },
    // 输入框字体
    keyu () {
      if (
        this.name != 0 &&
        this.phone_b.length == 18 &&
        this.img_get == false &&
        this.img_getb == false &&
        /^1[3456789]\d{9}$/.test(this.phone) &&
        this.codea.length == 4
      ) {
        this.btn = true
        this.btn_no = false
      } else {
        this.btn = false
        this.btn_no = true
      }
    },
    //   倒计时
    dao () {
      this.show = false
      const TIME_COUNT = 60
      if (!this.timer) {
        this.sj = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.sj > 0 && this.sj <= TIME_COUNT) {
            this.sj--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    //   获取验证码
    code () {
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$message({
          message: '手机号码有误，请重填',
          type: 'error'
        })
      } else {
        this.sms_a()
      }
    },
    // 验证码接口
    sms_a () {
      sms({
        data: { mobile: this.phone }
      }).then(res => {
        if (res.data.code == 10000) {
          this.$message({
            message: '今日以超过最大值',
            type: 'warning'
          })
        } else if (res.data.code == 10001) {
          this.$message({
            message: '短信发送成功',
            type: 'success'
          })
          this.codea = ''
          this.dao()
        } else if (res.data.code == 10002) {
          this.$message({
            message: '短信发送失败',
            type: 'error'
          })
        }
      })
    },
    btn_yes () {
      upload_img({
        data: {
          ent_id: this.ent_id.ent_id,
          ent_name: this.name,
          license: this.phone_b,
          mng_mobile: this.phone,
          sms: this.codea,
          license_data: this.xin,
          license_data_type: this.lei,
          aut_data: this.xin_b,
          aut_data_type: this.lei_b
        }
      }).then(res => {
        if (res.data.code == 10023) {
          this.$message({
            message: '认证成功',
            type: 'success'
          })
        } else if (res.data.code == 10024) {
          this.$message({
            message: '认证失败',
            type: 'error'
          })
        } else if (res.data.code == 10003) {
          this.$message({
            message: '验证码为空',
            type: 'warning'
          })
        } else if (res.data.code == 10005) {
          this.$message({
            message: '验证码错误',
            type: 'warning'
          })
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.uploada {
  background: url("../assets/background-forget.png") no-repeat;
  height: 100vh;
  background-position: center;
  .header {
    position: fixed;
    top: 0;
    height: 0.8rem;
    width: 100%;
    background-color: #f5f5f5;
    .center_title {
      margin: 0 auto;
      height: 100%;
      width: 9.55rem;
      background-color: #f5f5f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header_left {
        display: flex;
        align-items: center;
        .logo {
          height: 0.33rem;
          width: 0.35rem;
        }
        .text {
          height: 0.18rem;
          width: 0.75rem;
          margin-left: 0.05rem;
          margin-right: 0.23rem;
        }
      }
      .header_right {
        display: flex;
        align-items: center;
        .phone {
          height: 0.18rem;
          width: 0.17rem;
          margin-right: 0.05rem;
        }
        .phone_text {
          font-size: 0.14rem;
          color: #3c3a3e;
        }
      }
    }
    @media screen and (max-width: 1000px) {
      .center_title {
        width: 90%;
      }
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    .from {
      height: 6.08rem;
      width: 9.6rem;
      background-color: #fff;
      box-shadow: 0 0.03rem 0.25rem 0 rgba(0, 0, 0, 0.15);
      text-align: center;
      position: relative;
      .title_a {
        font-size: 0.22rem;
        font-weight: bold;
        color: #3c3a3e;
        margin: 0.22rem 0;
      }
      .box {
        width: 8.4rem;
        margin: 0 auto;
        position: relative;
        .code_box {
          position: absolute;
          right: 0;
          top: 1.32rem;
          .code {
            height: 0.42rem;
            width: 0.89rem;
            background-color: #e9e9e9;
            color: #615f62;
            text-align: center;
            line-height: 0.44rem;
            border-radius: 0 0.06rem 0.06rem 0;
            position: absolute;
            right: 0.01rem;
            top: 0.01rem;
          }
          .code_a {
            height: 0.42rem;
            width: 2.49rem;
            border: 0.01rem solid #979797;
            border-radius: 0.06rem;
            padding-left: 0.15rem;
          }
          .code_a::-webkit-input-placeholder {
            /* WebKit browsers */

            color: #b1b1b1;
          }

          .code_a:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */

            color: #b1b1b1;
          }

          .code_a::-moz-placeholder {
            /* Mozilla Firefox 19+ */

            color: #b1b1b1;
          }

          .code_a:-ms-input-placeholder {
            /* Internet Explorer 10+ */

            color: #b1b1b1;
          }
        }
      }
      .bottom_up {
        width: 6.45rem;
        position: absolute;
        right: 0.62rem;
        padding-top: 0.1rem;
        height: 3rem;
        .box_bottom {
          display: flex;
          justify-content: space-between;
          .xian {
            height: 1.2rem;
            border-left: 0.01rem solid #e6e6e6;
            margin-top: 0.28rem;
          }
          .box_btm_left {
            width: 50%;
            p {
              font-size: 0.18rem;
              font-weight: 500;
              color: #676669;
            }
            .picture {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 0.8rem;
              padding: 0 0.3rem;
              .image-view {
                .addbox {
                  float: left;
                  position: relative;
                  height: 1rem;
                  width: 1rem;
                  margin-bottom: 0.2rem;
                  text-align: center;
                  .img_1 {
                    height: 0.96rem;
                    width: 0.96rem;
                  }
                  input {
                    position: absolute;
                    left: 0;
                    height: 1rem;
                    width: 1rem;
                    opacity: 0;
                  }
                }
                .item {
                  position: relative;
                  height: 0.88rem;
                  width: 1.28rem;
                  margin: 0 auto;
                  .img_3 {
                    width: 1.3rem;
                    margin-left: 0.7rem;
                  }
                  .cancel-btn {
                    position: absolute;
                    right: -0.75rem;
                    top: 0;
                    display: block;
                    width: 0.2rem;
                    height: 0.2rem;
                    color: #fff;
                    line-height: 0.18rem;
                    text-align: center;
                    background: red;
                    border-radius: 50%;
                    cursor: pointer;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
              .view {
                clear: both;
              }
              .example {
                .img_2 {
                  height: 0.88rem;
                  width: 1.28rem;
                }
              }
            }
          }
          .box_btm_right {
            width: 50%;
            p {
              font-size: 0.18rem;
              font-weight: 500;
              color: #676669;
            }
            .picture {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 0.28rem;
              padding: 0 0.3rem;
              .image-view {
                .addbox {
                  float: left;
                  position: relative;
                  height: 1rem;
                  width: 1rem;
                  margin-bottom: 0.2rem;
                  text-align: center;
                  .img_1 {
                    height: 0.96rem;
                    width: 0.96rem;
                  }
                  input {
                    position: absolute;
                    left: 0;
                    height: 1rem;
                    width: 1rem;
                    opacity: 0;
                  }
                }
                .item {
                  position: relative;
                  height: 0.88rem;
                  width: 1.28rem;
                  margin: 0 auto;
                  .img_3 {
                    width: 0.88rem;
                    height: 1.1rem;
                    margin-left: 0.9rem;
                    margin-top: 0.1rem;
                  }
                  .cancel-btn {
                    position: absolute;
                    right: -0.55rem;
                    top: 0;
                    display: block;
                    width: 0.2rem;
                    height: 0.2rem;
                    color: #fff;
                    line-height: 0.18rem;
                    text-align: center;
                    background: red;
                    border-radius: 50%;
                    cursor: pointer;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
              .view {
                clear: both;
              }
              .example {
                .img_2 {
                  height: 1.2rem;
                  width: 0.88rem;
                  box-shadow: 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.5);
                }
              }
            }
          }
        }
        .btn_no {
          height: 0.44rem;
          width: 1.88rem;
          background: #b8c1ce;
          color: #fff;
          font-size: 0.22rem;
          font-weight: bold;
          line-height: 0.45rem;
          border-radius: 0.06rem;
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -0.94rem;
        }
        .btn {
          height: 0.44rem;
          width: 1.88rem;
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -0.94rem;
          background: -webkit-linear-gradient(
            left,
            #e65050,
            #fa6f6f
          ); /* Safari 5.1 - 6.0 */
          background: -o-linear-gradient(
            right,
            #e65050,
            #fa6f6f
          ); /* Opera 11.1 - 12.0 */
          background: -moz-linear-gradient(
            right,
            #e65050,
            #fa6f6f
          ); /* Firefox 3.6 - 15 */
          background: linear-gradient(
            to right,
            #e65050,
            #fa6f6f
          ); /* 标准的语法 */
          color: #fff;
          font-size: 0.22rem;
          font-weight: bold;
          line-height: 0.45rem;
          border-radius: 0.06rem;
        }
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width:100%;
  }
}
</style>
