<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="foote">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802033091" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <img src="../assets/images/beianhao.png" style="float:left;"/>
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11010802033091号</p>
      </a>
    </div>
</template>

<script>
export default {
  name: 'foote',

  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.foote {
    // background-color: #2a3041;
    height: 1.42rem;
    width: 100%;
    display: flex;
    background: #E7E8EB;
    border-top:0.01rem solid #ccc;
    align-items: center;
    a{
        margin: 0 auto;
        color:#888;
        font-size: 0.12rem;
    }
}
@media screen and (max-width: 12rem) {
      .foote {
        width: 12rem;
      }
    }
</style>
